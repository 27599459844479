p {
  margin-bottom: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: 1.5rem;
}

.navbar--jcb {
  background: url('../assets/white-dotted.png'),
    linear-gradient(90deg, #0a2758, #0c439d);
}
.navbar--shangzhang {
  background: linear-gradient(90deg, #0ba39b, #d8de02);
}
.navbar-container--jcb-menu {
  /* box-shadow: 0 1px 6px 0 #306ed9; */
  position: relative;
  height: 56px;
  /* background: url('../assets/white-dotted.png'),
    linear-gradient(90deg, #0a2758, #0c439d); */
  background-size: contain;
  background-position: 50%;
  color: #fff;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px rgba(68, 81, 121, 0.2);
  z-index: 999;
}

.navbar-container--jcb-menu .left-box {
  display: flex;
  justify-content: space-between;
}

.return-home-btn {
  width: 22px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.return-home-btn-cc {
  width: 62px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.navbar-container--jcb-menu .left-menu {
  display: flex;
  align-items: center;
  height: 56px;
  line-height: 56px;
  margin-left: 12px;
  margin-right: 15px;
}

.navbar-container--jcb-menu .right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-container--jcb-menu .logo {
  display: inline-block;
  flex-shrink: 0;
}

.navbar-container--jcb-menu .divide {
  display: inline-block;
  width: 1px;
  height: 14px;
  background: #fff;
  margin-left: 15px;
  margin-right: 13px;
}

.navbar-container--jcb-menu .menu-title {
  display: inline-block;
  margin-bottom: 0 !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  line-height: 1em !important;
  font-size: 14px;
  max-width: 240px;
  color: #fff;
  font-weight: 400;
}

.navbar-container--jcb-menu .gray {
  display: inline-block;
  padding: 6px 8px;
  border-radius: 2px;
  line-height: 1em !important;
  margin-left: 12px;
  background-color: #fff;
  color: #000;
}

.navbar-container--jcb-menu .return-btn-con {
  height: 56px;
  line-height: 56px;
  position: relative;
}

.navbar-container--jcb-menu .return-btn {
  display: inline-block;
  padding: 0 6px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navbar-container--jcb-menu .download-btn-con .return-btn-selected {
  background: #fff;
  color: #0c3f94;
}

.navbar-container--jcb-menu .return-btn-con .download-panel-con {
  position: absolute;
  padding-top: 20px;
  top: 56px;
  right: -75px;
  white-space: nowrap;
}

.navbar-container--jcb-menu
  .return-btn-con
  .download-panel-con
  .download-panel {
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.navbar-container--jcb-menu .return-btn-con .download-panel::before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  right: 140px;
  top: -14px;
  border: 8px solid;
  border-color: transparent transparent #fff;
}

.return-btn-con .download-panel-con .download-panel .download-panel-name {
  text-indent: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 14px;
  color: #4a4a4a;
}

.download-panel-con
  .download-panel
  .download-item:first-child
  .download-panel-name {
  text-indent: 0;
}

.return-btn-con .download-panel-con .download-panel p {
  font-size: 12px;
  color: #8c8c8c;
}

.download-panel-con .download-panel .download-item:first-child {
  vertical-align: top;
  display: inline-block;
  width: 148px;
  line-height: 0;
}

.download-panel-con .download-panel .download-item {
  white-space: normal;
  vertical-align: top;
  display: inline-block;
  width: 168px;
  line-height: 0;
}

.download-panel-con .download-panel .download-item .download-sub-title {
  line-height: 20px;
  font-size: 12px;
  color: #000;
  font-weight: bold;
}

.download-panel-con .download-panel .download-item:last-child {
  width: 148px;
}

.download-panel-con
  .download-panel
  .download-item:first-child
  .download-content {
  padding-left: 0px;
}

.download-panel-con .download-panel .download-item .download-content {
  padding-left: 20px;
}

.download-panel-con .download-panel .download-item .qr-container {
  /* padding-left: 20px;
  padding-right: 20px; */
  text-align: center;
  margin-bottom: 6px;
  border-right: 2px dashed #f0f0f0;
}

.download-panel-con .download-panel .download-item:first-child .qr-container {
  padding-left: 0px;
}

.download-panel-con .download-panel .download-item:last-child .qr-container {
  padding-right: 0;
  border-right: none;
}

.download-panel-con .download-panel .download-item .download {
  display: block;
  width: 66px;
  height: 24px;
  line-height: 24px;
  margin-top: 6px;
  margin-left: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 2px;
  border: solid 1px #35b1f6;
}
.download-panel-con .download-panel .download-item .download-cc {
  display: block;
  width: 66px;
  height: 24px;
  line-height: 24px;
  margin-top: 6px;
  margin-left: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 2px;
  border: solid 1px #bd192d;
}

.navbar-container--jcb-menu .user {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.navbar-container--jcb-menu .role {
  cursor: pointer;
}
.navbar-container--jcb-menu .role .role-name {
  display: inline-block;
  font-size: 12px;
  max-width: 62px;
  margin-right: 10px;
  color: #fff;
}

.navbar-container--jcb-menu .role .switch-button {
  display: inline-block;
  width: 46px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #1890ff;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.navbar-container--jcb-menu .logout {
  display: inline-block;
  font-size: 16px;
  line-height: 54px;
  height: 54px;
  cursor: pointer;
}

.custom-menu--jcb-menu .simple-title-con {
  position: relative;
  height: 56px;
  background: url('../assets/white-dotted.png'),
    linear-gradient(90deg, #0a2758, #0c439d);
  background-size: contain;
  background-position: 50%;
  color: #fff;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px rgba(68, 81, 121, 0.2);
  z-index: 999;
}

.custom-menu--jcb-menu .simple-title-con .simple-title {
  font-size: 16px;
  color: #fff;
  margin-right: 14px;
  vertical-align: middle;
}

.custom-menu--jcb-menu .simple-title-con .module-name {
  display: inline-block;
  padding: 0 12px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 14px;
  background: #fff;
  color: #1e90ff;
  vertical-align: middle;
}

.custom-menu--jcb-menu .fixed-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.custom-menu--jcb-menu .shadow {
  height: 56px;
}

.navbar-container--jcb-menu .navbar-menu {
  height: 53px;
  display: flex;
  align-items: center;
  padding-left: 50px;
  border: 0;
}

.navbar-container--jcb-menu .navbar-menu li {
  height: 60px;
  line-height: 64px;
}

.ant-modal-root .role-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  line-height: 46px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.ant-modal-root .role-item .role-item-name {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
}

.custom-menu--jcb-menu .ant-menu-item {
  margin-right: 20px !important;
  margin-bottom: 3px !important;
  padding: 0 10px !important;
  margin: 0 !important;
}

.custom-menu--jcb-menu .ant-menu {
  background: transparent !important;
  color: #fff !important;
}

.custom-menu--jcb-menu .ant-menu-item-selected {
  border-bottom: 2px solid #fff !important;
  color: #fff !important;
}

.custom-menu--jcb-menu .ant-menu-item-active {
  border-bottom: 2px solid #fff !important;
  color: #fff !important;
}

.custom-menu--jcb-menu .ant-menu .ant-menu-submenu-selected {
  border-bottom: 2px solid #fff !important;
  color: #fff !important;
}

.custom-menu--jcb-menu .ant-menu-submenu-active {
  border-bottom: 2px solid #fff !important;
  color: #fff !important;
}

.custom-menu--jcb-menu .ant-menu-submenu {
  margin-right: 20px !important;
  padding: 0 10px !important;
}

.custom-menu--jcb-menu .ant-menu-submenu-title {
  padding: 0px !important;
}

.custom-menu--jcb-menu .ant-menu-submenu-title:hover {
  color: #fff !important;
}

.custom-sub-menu--jcb-menu .ant-menu-item-active {
  background-color: #1890ff !important;
  color: #fff !important;
  border-bottom: none !important;
}

.custom-sub-menu--jcb-menu .ant-menu-item {
  transition: none !important;
  margin-right: 0 !important;
}

.custom-sub-menu--jcb-menu .ant-menu {
  background-color: #fff !important;
  color: #000 !important;
}

.custom-sub-menu--jcb-menu .ant-menu-item-selected {
  background-color: #1890ff !important;
  color: #fff !important;
  border-bottom: none !important;
}
/* 创城 */
.custom-sub-menu--cc-menu .ant-menu-item-active {
  background-color: #bd192d !important;
  color: #fff !important;
  border-bottom: none !important;
}

.custom-sub-menu--cc-menu .ant-menu-item {
  transition: none !important;
  margin-right: 0 !important;
}

.custom-sub-menu--cc-menu .ant-menu {
  background-color: #fff !important;
  color: #000 !important;
}

.custom-sub-menu--cc-menu .ant-menu-item-selected {
  background-color: #bd192d !important;
  color: #fff !important;
  border-bottom: none !important;
}
/* 农业 */
.custom-sub-menu--ny-menu .ant-menu-item-active {
  background-color: #07c160 !important;
  color: #fff !important;
  border-bottom: none !important;
}

.custom-sub-menu--ny-menu .ant-menu-item {
  transition: none !important;
  margin-right: 0 !important;
}

.custom-sub-menu--ny-menu .ant-menu {
  background-color: #fff !important;
  color: #000 !important;
}

.custom-sub-menu--ny-menu .ant-menu-item-selected {
  background-color: #07c160 !important;
  color: #fff !important;
  border-bottom: none !important;
}

.dropdown--jcb-menu .ant-dropdown-menu-item {
  line-height: 32px;
  text-align: center;
}

.display-none {
  display: none;
}

@media screen and (max-width: 1400px) {
  .custom-menu--jcb-menu .ant-menu-item {
    margin-right: 6px !important;
    padding: 0 6px !important;
  }
  .custom-menu--jcb-menu .ant-menu-submenu {
    margin-right: 6px !important;
    padding: 0 6px !important;
  }

  .navbar-container--jcb-menu {
    padding-right: 12px;
  }

  .navbar-container--jcb-menu .navbar-menu {
    padding-left: 10px;
  }

  .navbar-container--jcb-menu .left-menu {
    margin-right: 8px !important;
  }
}

@media screen and (max-width: 1000px) {
  .custom-menu--jcb-menu .ant-menu-item {
    margin-right: 4px !important;
    padding: 0 4px !important;
  }
  .custom-menu--jcb-menu .ant-menu-submenu {
    margin-right: 4px !important;
    padding: 0 4px !important;
  }
  .navbar-container--jcb-menu {
    padding-right: 12px;
  }
  .navbar-container--jcb-menu .navbar-menu {
    padding-left: 0px;
  }
  .navbar-container--jcb-menu .left-menu {
    width: 300px;
    flex-basis: 300px;
    margin-right: 0px !important;
  }
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 0px solid #409eff !important;
}
.ant-menu-submenu .ant-menu-submenu-horizontal {
  margin-left: 0 !important;
}
.ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0 !important;
}
.dropdown--jcb-menu .ant-dropdown-menu-item:hover {
  background: rgba(189, 25, 45, 0.1);
}
/* 头像 */
.photo-box {
  width: 35px;
  height: 35px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 8px;
}
